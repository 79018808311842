<template>
  <div id="home" class="template">
    <!-- warning/vacation message -->
    <!--
    <div
      id="warning-message"
      class="red"
      v-show="this.showWarning"
      @click="this.showWarning = false"
    >
      {{ $t("home.warning") }}
      <div id="warning-svg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Capa_1"
          x="0px"
          y="0px"
          viewBox="0 0 512.021 512.021"
          style="enable-background: new 0 0 512.021 512.021"
          xml:space="preserve"
          width="512"
          height="512"
        >
          <g>
            <path
              d="M301.258,256.01L502.645,54.645c12.501-12.501,12.501-32.769,0-45.269c-12.501-12.501-32.769-12.501-45.269,0l0,0   L256.01,210.762L54.645,9.376c-12.501-12.501-32.769-12.501-45.269,0s-12.501,32.769,0,45.269L210.762,256.01L9.376,457.376   c-12.501,12.501-12.501,32.769,0,45.269s32.769,12.501,45.269,0L256.01,301.258l201.365,201.387   c12.501,12.501,32.769,12.501,45.269,0c12.501-12.501,12.501-32.769,0-45.269L301.258,256.01z"
            />
          </g>
        </svg>
      </div>
    </div>
  -->
    <div id="top">
      <div class="title">{{ $t("home.title") }}</div>
      <div class="description">
        {{ $t("home.description") }}
      </div>
      <div class="contact">
        {{ $t("home.questions") }}<br />
        <div>
          {{ $t("home.c1") }}
          <router-link to="/reserve"
            ><u>{{ $t("home.c2") }}</u></router-link
          >
          {{ $t("home.c3") }}.
        </div>
      </div>
      <div class="contact-buttons">
        <button>
          <router-link to="/reserve">{{ $t("home.contact") }}</router-link>
        </button>
        <button>
          <router-link to="/menu">{{ $t("home.menu") }}</router-link>
        </button>
      </div>
    </div>
    <div id="bottom"></div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Home",
  data() {
    return {
      showWarning: true,
    };
  },
};
</script>

<style scoped>
#home {
  position: relative;
}
#top {
  position: absolute;
  height: 100%;
  width: 35%;
  min-width: 450px;
  max-width: 1000px;
  z-index: 9;
  color: #fff;
  background-color: rgba(41, 31, 37, 0.85);
}
#bottom {
  width: 100%;
  height: 100%;
  text-align: left;
  padding-left: calc(35% + 5px);
  background-image: url("../assets/pictures/one.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}
#top,
#bottom {
  padding-top: 65px;
}

.title {
  font-size: 35px;
  text-transform: uppercase;
}
.title,
.description,
.contact {
  width: 90%;
}
.title,
.description {
  font-family: "SourceSansPro Light";
}
.description,
.contact {
  margin-top: 25px;
  letter-spacing: 0.4px;
  line-height: 21px;
}
.title,
.description,
.contact {
  text-align: left;
  padding-left: 30px;
}
.contact-buttons {
  display: none;
}
.contact {
  font-size: 18px;
}
.contact div {
  margin-top: 5px;
}

#call-us {
  color: #fff;
}
#call-us:hover {
  color: #f3efe5;
}
#warning-message {
  position: absolute;
  display: flex;
  font-size: 18px;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  align-items: center;
  justify-content: center;
  z-index: 20;
  width: 100%;
}
#warning-message #warning-svg {
  position: absolute;
  right: 15px;
  top: 15px;
}
#warning-message #warning-svg:hover {
  cursor: pointer;
}
#warning-message #warning-svg svg {
  height: 16px;
  width: 16px;
}
#warning-message.red {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
#warning-message.grey {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
#warning-message.white {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

@media only screen and (max-width: 600px) {
  .title,
  .description,
  .contact {
    text-align: center;
  }
  .title {
    font-family: revert;
    font-weight: bold;
    font-size: 39px;
    text-align: left;
    text-transform: unset;
    margin-top: 85px;
  }
  .description,
  .contact {
    display: none;
  }
  .contact-buttons {
    display: flex;
    padding-left: 30px;
    margin-top: 15px;
  }
  .contact-buttons button {
    border: 2px dotted #fff;
    min-width: 100px;
    font-size: 16px;
    padding: 15px;
    margin-right: 15px;
    font-weight: bold;
    color: #fff;
  }
  .contact-buttons button a {
    font-size: 14px;
    text-transform: uppercase;
  }

  #top {
    position: static;
    width: 100%;
    padding-top: 35px;
    padding-bottom: 35px;
    min-width: unset;
    max-width: unset;
    background-color: rgba(0, 0, 0, 0);
  }
  #bottom {
    padding-left: unset;
    display: none;
  }
  #warning-message {
    font-size: 16px;
    padding: 25px 35px;
  }
  #warning-message #warning-svg {
    top: 12px;
  }
}
</style>
